
export default {
  name: 'MoleculeSidebarResults',
  props: {
    enableEmbedVersion: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isSpaceListExpanded: false,
      listData: [],
      filters: {}
    }
  },
  computed: {
    projectData() {
      return this.$store.state.project.project
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
    unitOfMeasure() {
      return this.$store.state.base.meta.generalConfig.unitOfMeasure
    },
    unitOfMeasureString() {
      return this.$store.state.base.meta.generalConfig.unitOfMeasure === 1 ? 'sqft' : this.$t("sqm")
    }
  },
  watch: {
    '$store.state.project.project.buildings': function () {
      this.refreshListData()
    },
    '$store.state.project.activeProject': function (value) {
      this.refreshListData()
    },
    '$store.state.project.filters': {
      deep: true,
      handler: function (filters) {
        this.filters = filters;
        this.refreshListData()
      }
    }
  },
  mounted() {
    this.refreshListData()
  },
  methods: {
    refreshListData() {
      this.filters = {...this.$store.state.project.filters};
      this.listData = [];
      const buildings = [...this.projectData.buildings];
      buildings
        .filter((b) => {
          if (this.filters.building) {
            return b.id === parseInt(this.filters.building);
          }
          return true;
        })
        .sort((x, y) => x.order - y.order)
        .forEach((b) => {
          const building = {
            ...b,
            floors: [],
          };
          const floors = [...this.projectData.floors];
          floors
            .sort((a, b) =>
              a.order_no > b.order_no ? 1 : b.order_no > a.order_no ? -1 : 0
            )
            .filter((f) => {
              if (this.filters.floor) {
                return (
                  f.no === parseInt(this.filters.floor) &&
                  f.building_id === b.id
                );
              }
              return f.building_id === b.id;
            })
            .forEach((f, i) => {
              const floor = {
                ...f,
                availableSurface: 0,
                isFullyLeased: false,
                leasedSpaces: 0,
                spaces: [],
              };
              const spaces = [...this.projectData.spaces];
              spaces
                .sort((a, b) =>
                  a.space_code > b.space_code
                    ? 1
                    : b.space_code > a.space_code
                      ? -1
                      : 0
                )
                .filter((s) => {
                  return s.floor_id === f.id;
                })
                .forEach((s, i) => {
                  const space = {
                    ...s,
                    people: 0,
                  };
                  const unitSQM = s.sqm / 10.763910417;
                  space.people = Math.floor(space.sqm / 8);
                  if (this.unitOfMeasure === 1) {
                    space.people = Math.floor(unitSQM / 8);
                  }
                  const spaceCodes = space.space_code.split(";");
                  if (spaceCodes.length === 1 && space.available) {
                    floor.availableSurface += space.sqm;
                  }
                  if (
                    !space.available &&
                    space.visibility_status !== "available"
                  ) {
                    floor.leasedSpaces += 1;
                  }
                  if (this.filters.surface) {
                    if (s.sqm_range === this.filters.surface && s.available) {
                      floor.spaces.push(space);
                    }
                  } else {
                    floor.spaces.push(space);
                  }
                });

              if (floor.leasedSpaces === floor.spaces.length) {
                floor.isFullyLeased = true;
              }

              floor.spaces.sort((a, b) => {
                if (a.sqm < b.sqm) {
                  return -1;
                }
                if (a.sqm > b.sqm) {
                  return 1;
                }
                return 0;
              });

              if (this.filters.surface) {
                if (floor.spaces.length) {
                  building.floors.push(floor);
                }
              } else {
                building.floors.push(floor);
              }
            });
          this.listData.push(building);
        });
    },
  }
}
